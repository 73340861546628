(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/hooks/assets/javascripts/use-get-win-amount.js') >= 0) return;  svs.modules.push('/components/pool-betting/hooks/assets/javascripts/use-get-win-amount.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  useDrawResultFromCurrentWagerDraw
} = svs.components.tipsen.hooksWager;
const {
  useGetPlayerWagerQuery,
  useGetMarketplaceWagerdetailsQuery
} = svs.components.tipsen.store.tipsenApi;
const {
  format
} = svs.utils;
const getAddonDrawWin = _ref => {
  var _addonBet$wins;
  let {
    addonBet,
    allDrawNumbers,
    drawNumber
  } = _ref;
  if (!drawNumber || !addonBet || !((_addonBet$wins = addonBet.wins) !== null && _addonBet$wins !== void 0 && _addonBet$wins.length) > 0 || !allDrawNumbers || !allDrawNumbers.length) {
    return 0;
  }
  let indexOfSelectedDraw = 0;
  for (const tempDrawNumber of allDrawNumbers) {
    if (tempDrawNumber === drawNumber) {
      break;
    }
    indexOfSelectedDraw++;
  }
  const relatedAddonDrawNumber = addonBet.addonCompletedDrawNumbers[indexOfSelectedDraw] || undefined;
  const addonWin = addonBet.wins.find(win => win.drawNumber === relatedAddonDrawNumber);
  const addonWinAmount = addonWin ? parseInt(addonWin.amount, 10) : 0;
  return addonWinAmount;
};
const getWinAmountsForCurrentDraw = _ref2 => {
  let {
    drawNumber,
    productWins,
    addonBet,
    allDrawNumbers
  } = _ref2;
  let productWinAmountForDraw = 0;
  let addOnWinAmountForDraw = 0;
  productWins === null || productWins === void 0 || productWins.forEach(productWin => {
    if (productWin.drawNumber === drawNumber) {
      productWinAmountForDraw += parseInt(productWin.amount, 10);
    }
  });
  addOnWinAmountForDraw = getAddonDrawWin({
    addonBet,
    allDrawNumbers,
    drawNumber
  });
  const totalWinForDraw = productWinAmountForDraw + addOnWinAmountForDraw;
  return [productWinAmountForDraw, addOnWinAmountForDraw, totalWinForDraw];
};
const getGroupWinAmounts = function (drawNumber) {
  let groupWinAmounts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  const currentGroupWinAmounts = groupWinAmounts[drawNumber] || groupWinAmounts.total;
  return {
    groupWinAmount: currentGroupWinAmounts !== null && currentGroupWinAmounts !== void 0 && currentGroupWinAmounts.group ? format.CurrencyFromJupiterString(currentGroupWinAmounts.group) : 0,
    playerWinAmount: currentGroupWinAmounts !== null && currentGroupWinAmounts !== void 0 && currentGroupWinAmounts.share ? format.CurrencyFromJupiterString(currentGroupWinAmounts.share) : 0
  };
};

const calculateGroupWinAmounts = _ref3 => {
  let {
    winDivisions,
    winDivisionsRowCount,
    customerShareCount,
    totalShareCount,
    rowPriceMulti = 1
  } = _ref3;
  let groupWinAmount = 0;
  let playerWinAmount = 0;
  if (!Array.isArray(winDivisions) || !Array.isArray(winDivisionsRowCount)) {
    return {
      groupWinAmount,
      playerWinAmount
    };
  }
  for (const {
    winDiv,
    winValue
  } of winDivisions) {
    const winDivisionWinValue = (winDivisionsRowCount === null || winDivisionsRowCount === void 0 ? void 0 : winDivisionsRowCount[winDiv - 1]) * format.CurrencyFromJupiterString(winValue) * rowPriceMulti;
    const winDivisionShareValue = winDivisionWinValue / totalShareCount;
    playerWinAmount += winDivisionShareValue * customerShareCount;
    groupWinAmount += winDivisionShareValue * totalShareCount;
  }
  return {
    groupWinAmount: groupWinAmount.toFixed(2),
    playerWinAmount: playerWinAmount.toFixed(2)
  };
};
const calculatePlayerWinAmount = _ref4 => {
  let {
    winDivisions,
    winDivisionsRowCount,
    rowPriceMulti = 1
  } = _ref4;
  let playerWinAmount = 0;
  if (!Array.isArray(winDivisions) || !Array.isArray(winDivisionsRowCount)) {
    return playerWinAmount;
  }
  for (const {
    winDiv,
    winValue
  } of winDivisions) {
    playerWinAmount += (winDivisionsRowCount === null || winDivisionsRowCount === void 0 ? void 0 : winDivisionsRowCount[winDiv - 1]) * format.CurrencyFromJupiterString(winValue) * rowPriceMulti;
  }
  return playerWinAmount;
};

const useGetWinAmount = _ref5 => {
  let {
    wagerId,
    winDivisionsRowCount,
    activeDrawNumber,
    allDrawNumbers,
    hasMaskedOutcomes = false
  } = _ref5;
  const {
    data: wagerData
  } = useGetPlayerWagerQuery({
    wagerId
  }, {
    skip: !wagerId
  });
  const {
    addonBet = {},
    productBet = {}
  } = wagerData !== null && wagerData !== void 0 ? wagerData : {};
  const {
    completedDrawNumbers = [],
    drawNumber,
    gameBoard,
    isGroup,
    properties = {},
    wins: productWins
  } = productBet;
  const currentDrawNumber = activeDrawNumber || drawNumber;
  const {
    rowPriceMulti = 1
  } = gameBoard || {};
  const {
    customerShareCount,
    totalShareCount,
    groupId,
    groupWinAmounts = {}
  } = properties;
  const isDrawCompleted = completedDrawNumbers.includes(currentDrawNumber) && !hasMaskedOutcomes;

  const {
    data: marketPlaceData
  } = useGetMarketplaceWagerdetailsQuery({
    groupId,
    wagerId
  }, {
    skip: !isGroup
  });

  const [{
    data: drawResultData
  }] = useDrawResultFromCurrentWagerDraw({
    wagerId
  });
  const [, winDivisions] = drawResultData;
  const groupWinAmountsData = isDrawCompleted ? getGroupWinAmounts(currentDrawNumber, groupWinAmounts) : calculateGroupWinAmounts({
    winDivisions,
    winDivisionsRowCount,
    customerShareCount,
    totalShareCount,
    rowPriceMulti
  });
  const groupData = isGroup && marketPlaceData ? _objectSpread({
    groupName: marketPlaceData === null || marketPlaceData === void 0 ? void 0 : marketPlaceData.groupName
  }, groupWinAmountsData) : undefined;
  let winAmount = 0;
  let addonWinAmount = 0;
  let productWinAmount = 0;
  if (isDrawCompleted && !hasMaskedOutcomes) {
    const [productWinAmountForDraw, addOnWinAmountForDraw, totalWinForDraw] = getWinAmountsForCurrentDraw({
      drawNumber: currentDrawNumber,
      productWins,
      addonBet,
      allDrawNumbers
    });
    winAmount = totalWinForDraw;
    addonWinAmount = addOnWinAmountForDraw;
    productWinAmount = productWinAmountForDraw;
  } else if (isGroup) {
    winAmount = groupData === null || groupData === void 0 ? void 0 : groupData.playerWinAmount;
  } else {
    winAmount = calculatePlayerWinAmount({
      winDivisions,
      winDivisionsRowCount,
      rowPriceMulti
    });
  }
  winAmount = format.CurrencyFromJupiterString(winAmount);
  return {
    isDrawCompleted,
    currentDrawNumber,
    winAmount,
    groupData,
    addonWinAmount,
    productWinAmount
  };
};
setGlobal('svs.components.poolBetting.hooks', {
  useGetWinAmount
});

 })(window);